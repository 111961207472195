import React from 'react';
import { useContasAReceber } from '../../ContasAReceberContext';

import { ReceiptModal } from '../../styles';
import { Button, Modal } from 'react-bootstrap';
import { AiOutlinePrinter } from 'react-icons/ai';
import { GoDownload } from 'react-icons/go';

export const ModalRecibo: React.FC = () => {
  const { setShowModal, showModal, recibo, gerarEbaixarPDF } =
    useContasAReceber();

  return (
    <ReceiptModal
      show={showModal}
      onHide={() => {
        setShowModal(false);
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>Visualização do Recibo</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div
          className="receipt"
          style={{
            whiteSpace: 'pre-wrap',
            fontFamily: 'Courier New, monospace',
            fontSize: '12px',
            lineHeight: '1.6',
            letterSpacing: '0px',
            marginTop: '20px',
            marginBottom: '20px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            color: 'black',
          }}
        >
          <div
            style={{
              border: '1px solid grey',
              paddingLeft: '35px',
              paddingTop: '20px',
              paddingBottom: '40px',
              paddingRight: '5px',
            }}
          >
            {recibo}
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="primary"
          onClick={() => {
            gerarEbaixarPDF(recibo, true);
          }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '3px',
          }}
        >
          Imprimir
          <AiOutlinePrinter size={24} className="btn-icon" />
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            gerarEbaixarPDF(recibo);
          }}
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '3px',
          }}
        >
          Baixar
          <GoDownload size={24} className="btn-icon" />
        </Button>
      </Modal.Footer>
    </ReceiptModal>
  );
};
