import styled from 'styled-components';
import FormAtlas from '@atlaskit/form';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;
  h3.title-page {
    font-size: 24px;
    font-weight: 500;
  }
`;

export const Form = styled(FormAtlas)`
  padding: 20px;
  border-radius: 5px;
`;
