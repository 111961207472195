import React from 'react';
import { ContasAReceberContextProvider } from './ContasAReceberContext';
import ContasAReceberContent from './ContasAReceberContent';

const ContasAReceber: React.FC = () => {
  return (
    <ContasAReceberContextProvider>
      <ContasAReceberContent />
    </ContasAReceberContextProvider>
  );
};

export default ContasAReceber;
