import { GridRowParams } from '@material-ui/data-grid';
import React, { useCallback, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import DefaultLoader from '~/components/DefaultLoader';
import FormDefault from '~/components/FormDefault';
import InputSelect from '~/components/Inputs/InputSelect';
import InputText from '~/components/Inputs/InputText';
import Search from '~/components/Search';
import TreeView from '~/components/TreeView';
import api from '~/services/api';
import { useForm } from 'react-hook-form';

import { Container } from './styles';
import { ControleAcessoProps, ModulosTelas, ModulosTelasReturn } from './types';
import ToggleDefault from '~/components/ToggleDefault';
import { getUserData } from '~/services/user';
import { yupResolver } from '@hookform/resolvers/yup';
import { schema } from './Validations';

const ControleDeAcesso: React.FC = () => {
  const { flg_superadmin } = getUserData();

  const {
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
    reValidateMode: 'onBlur',
  });

  const [loader, setLoader] = useState<boolean>(false);
  const [isUpdate, setUpdate] = useState<boolean>(false);
  const [invalidTreeView, setInvalidTreeView] = useState<boolean>(false);

  const [iniInicializado, setInitInicializado] = useState(false);

  const [showSearch, setShowSearch] = useState(true);

  const [publico, setPublico] = useState<boolean>(false);
  const [formData, setFormData] = useState<ControleAcessoProps>({
    cod_controle: {
      value: 0,
      isInvalid: false,
      isRequired: false,
    },
    cod_controle_pai: {
      value: { value: 0, label: 'Nenhum' },
      isInvalid: true,
      isRequired: false,
    },
    des_controle: {
      value: '',
      isInvalid: true,
      isRequired: true,
    },
  });

  const [optionsSelect, setOptionsSelect] = useState([]);

  const [telas, setTelas] = useState<ModulosTelas[]>([]);

  const [manterDados, setManterDados] = useState(false);

  useEffect(() => {
    (async () => {
      getHerdarPreferencias();
    })();
  }, []);

  const getHerdarPreferencias = useCallback(async () => {
    const { data } = await api.get('/controle-acesso');
    if (data.success) {
      data.data.sort(function (a: any, b: any) {
        if (a.des_controle < b.des_controle) {
          return -1;
        }
        if (a.des_controle > b.des_controle) {
          return 1;
        }
        return 0;
      });
      const options = data.data.map((perfil: ControleAcessoProps) => ({
        label: perfil.des_controle,
        value: perfil.cod_controle,
      }));

      options.unshift({
        label: 'Nenhum',
        value: 0,
      });
      setOptionsSelect(options);
    }
  }, []);

  const resetTelas = useCallback(() => {
    (async () => {
      const { data } = await api.get('/telas');
      if (data.success) {
        const telasSet = data.data.map((item: ModulosTelas) => {
          const telasChildren: any = [];
          item.telas.forEach((i) => {
            if (i.cod_tela !== 204) {
              telasChildren.push({
                id: i.cod_tela,
                text: i.des_tela,
                state: 2,
                isLeaf: true,
                lib_icon: i.lib_icon,
                des_icon: i.des_icon,
                check: undefined,
                num_ordem_menu: i.num_ordem_menu,
              });
            }
          });

          return {
            text: item.name,
            children: telasChildren,
            isLeaf: true,
            check: undefined,
            num_ordem_menu: item.num_ordem_menu,
          };
        });
        const filteredTelasSet = telasSet.filter(
          (item: any) =>
            Array.isArray(item.children) && item.children.length > 0,
        );
        setTelas(filteredTelasSet);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const { data } = await api.get('/telas');
      if (data.success) {
        const telasSet = data.data.map((item: ModulosTelas) => {
          const telasChildren: any = [];
          item.telas.forEach((i) => {
            if (i.cod_tela !== 204) {
              telasChildren.push({
                id: i.cod_tela,
                text: i.des_tela,
                state: 2,
                isLeaf: true,
                lib_icon: i.lib_icon,
                des_icon: i.des_icon,
                check: undefined,
                num_ordem_menu: i.num_ordem_menu,
              });
            }
          });

          return {
            text: item.name,
            children: telasChildren,
            isLeaf: true,
            check: undefined,
            num_ordem_menu: item.num_ordem_menu,
          };
        });

        const filteredTelasSet = telasSet.filter(
          (item: any) =>
            Array.isArray(item.children) && item.children.length > 0,
        );
        setTelas(filteredTelasSet);
      }
    })();
  }, []);

  const resetFormData = () => {
    setUpdate(false);
    reset();
    setFormData({
      cod_controle: {
        value: 0,
        isInvalid: false,
        isRequired: false,
      },
      des_controle: {
        value: '',
        isInvalid: false,
        isRequired: true,
      },
      cod_controle_pai: {
        value: { value: 0, label: 'Nenhum' },
        isInvalid: false,
        isRequired: true,
      },
    });
    setPublico(false);
    resetTelas();
  };

  const handleChangeCheckedOptions = useCallback(
    (data: any) => {
      setValue('telas_tree', data);
      setInvalidTreeView(false);
    },
    [setValue],
  );

  const handleChangeDescricao = useCallback(
    (val: string, isInvalid: boolean) => {
      setFormData({
        ...formData,
        des_controle: {
          ...formData.des_controle,
          value: val.trimStart().toUpperCase(),
          isInvalid,
        },
      });

      setValue('des_controle', val.trimStart().toUpperCase());
    },
    [formData, setValue],
  );

  const handleChangePerfilPai = useCallback(
    (val: { label: string; value: number }, isInvalid: boolean) => {
      setFormData({
        ...formData,
        cod_controle_pai: {
          ...formData.cod_controle_pai,
          value: val,
          isInvalid,
        },
      });

      setValue('cod_controle_pai', val);
    },
    [formData, setValue],
  );

  const onSave = handleSubmit(async (dataForm: any) => {
    try {
      setLoader(true);

      if (dataForm.telas_tree !== undefined) {
        let telasForm = dataForm.telas_tree.flatMap(
          (telaTree: ModulosTelasReturn) =>
            telaTree.children.map((itemTree: any) => ({
              cod_tela: itemTree.id,
              flg_visivel: itemTree.state === 1,
              isLeaf: true,
            })),
        );

        //  removendo tela home da listagem
        telasForm = telasForm.filter((tela: any) => tela.cod_tela !== 204);

        const countVisiveis = telasForm.filter(
          (telaForm: any) => telaForm.flg_visivel,
        ).length;

        if (countVisiveis <= 0) {
          setInitInicializado(true);
          setInvalidTreeView(true);
          toast.warning('Nenhuma tela foi selecionada ');
          setLoader(false);
          return;
        }

        if (isUpdate) {
          const { data } = await api.put(
            `/controle-acesso/${formData?.cod_controle.value}`,
            {
              telas: telasForm,
              des_controle: dataForm.des_controle,
              cod_controle_pai: dataForm.cod_controle_pai
                ? dataForm.cod_controle_pai.value
                : null,
              flg_publico: dataForm.flg_publico,
            },
          );

          if (data.success) {
            toast.success(data.message);
            resetFormData();
            setUpdate(false);
            getHerdarPreferencias();
            setShowSearch(true);
          }
          setLoader(false);
          return;
        }

        const { data } = await api.post('/controle-acesso', {
          telas: telasForm,
          des_controle: dataForm.des_controle,
          cod_controle_pai: dataForm.cod_controle_pai
            ? dataForm.cod_controle_pai.value
            : null,
          flg_publico: dataForm.flg_publico,
        });

        setLoader(false);

        if (data.success) {
          toast.success(data.message);
          resetFormData();
          getHerdarPreferencias();
        }
      } else {
        setLoader(false);
        toast.warning(' Nenhuma tela foi foi marcada.');
      }
    } catch (e: any) {
      setLoader(false);
    }
  });

  const onRowClick = async (param: GridRowParams) => {
    setLoader(true);
    const { row } = param;

    setUpdate(true);
    const {
      cod_controle,
      des_controle,
      cod_controle_pai,
      des_controle_pai,
      flg_publico,
    } = row;
    setPublico(flg_publico);
    setFormData({
      cod_controle: {
        value: cod_controle,
        isInvalid: false,
        isRequired: false,
      },
      cod_controle_pai: {
        value: { value: cod_controle_pai, label: des_controle_pai },
        isInvalid: false,
        isRequired: true,
      },
      des_controle: {
        value: des_controle,
        isInvalid: false,
        isRequired: true,
      },
    });
    setValue('cod_controle_pai', {
      value: cod_controle_pai,
      label: des_controle_pai,
    });
    setValue('des_controle', des_controle);

    setValue('flg_publico', flg_publico);

    const { data } = await api.get(`/telas/${cod_controle}`);

    if (data.success) {
      const telasSet = data.data.map((item: ModulosTelas) => {
        const telasChildren: any = [];
        item.telas.forEach((i) => {
          if (i.cod_tela !== 204) {
            telasChildren.push({
              id: i.cod_tela,
              text: i.des_tela,
              state: i.flg_visivel ? 1 : 2,
              isLeaf: true,
              lib_icon: i.lib_icon,
              des_icon: i.des_icon,
              check: i.flg_visivel,
              num_ordem_menu: i.num_ordem_menu,
            });
          }
        });

        const findStateNot = telasChildren.find(
          (itemNot: any) => itemNot.state === 2,
        );

        return {
          text: item.name,
          children: telasChildren,
          state: findStateNot ? 2 : 1,
          check: findStateNot ? undefined : true,
          isLeaf: true,
          num_ordem_menu: item.num_ordem_menu,
        };
      });
      const filteredTelasSet = telasSet.filter(
        (item: any) => Array.isArray(item.children) && item.children.length > 0,
      );
      setTelas(filteredTelasSet);
    }

    setShowSearch(false);
    setLoader(false);
  };

  const newData = () => {
    setShowSearch(false);
  };

  const handleSeacrhTelasPerfilPai = async (codControle: number) => {
    if (codControle === 0) {
      resetTelas();
    }
    if (codControle) {
      const { data } = await api.get(`/telas/${codControle}`);
      if (data.success) {
        const telasSet = data.data.map((item: ModulosTelas) => {
          const telasChildren: any = [];

          item.telas.forEach((i) => {
            if (i.cod_tela !== 204) {
              telasChildren.push({
                id: i.cod_tela,
                text: i.des_tela,
                state: i.flg_visivel ? 1 : 2,
                isLeaf: true,
                lib_icon: i.lib_icon,
                des_icon: i.des_icon,
                check: i.flg_visivel,
                num_ordem_menu: i.num_ordem_menu,
              });
            }
          });

          const findStateNot = telasChildren.find(
            (itemNot: any) => itemNot.state === 2,
          );

          return {
            text: item.name,
            children: telasChildren,
            state: findStateNot ? 2 : 1,
            check: findStateNot ? undefined : true,
            isLeaf: true,
            num_ordem_menu: item.num_ordem_menu,
          };
        });

        const filteredTelasSet = telasSet.filter(
          (item: any) =>
            Array.isArray(item.children) && item.children.length > 0,
        );
        setTelas(filteredTelasSet);
      }
    }
  };

  const handleDeletePerfil = async () => {
    const { data } = await api.delete(
      `/controle-acesso/${formData?.cod_controle.value}`,
    );

    if (data.success) {
      toast.success(data.message);
      setShowSearch(true);
      setUpdate(false);
      getHerdarPreferencias();
      resetFormData();
    }
  };

  const handleSwitch = useCallback(() => {
    setManterDados(!manterDados);
  }, [manterDados]);

  if (loader) {
    return (
      <Container>
        <DefaultLoader />
      </Container>
    );
  }

  return (
    <Container>
      {showSearch && (
        <Search
          codTela={1}
          newData={() => {
            newData();
          }}
          onRowClick={onRowClick}
        />
      )}

      {!showSearch && (
        <FormDefault
          codTela={1}
          title="Controle de Acesso"
          codigoRegistro={[
            {
              value: formData.cod_controle.value,
              des_campo: 'Código',
            },
          ]}
          onSave={onSave}
          onCancel={() => {
            resetFormData();
            setShowSearch(true);
          }}
          onNew={() => {
            resetFormData();
            setUpdate(false);
          }}
          onDelete={handleDeletePerfil}
          onClearFields={resetFormData}
          onReturnSearch={() => {
            setShowSearch(true);
            resetFormData();
          }}
          isUpdate={isUpdate}
          onSwitch={handleSwitch}
        >
          <div className="row">
            <div className="col-sm-12 col-md-3">
              <InputText
                label="Descrição"
                value={formData.des_controle.value}
                maxLength={30}
                minLength={1}
                isDisabled={false}
                placeholder="Descrição"
                isEmpty
                iniInicializado={!!errors.des_controle}
                isRequired
                setInvalid={!!errors.des_controle}
                onChange={(newValue: string, isInvalid = true) => {
                  handleChangeDescricao(newValue, isInvalid);
                }}
              />
            </div>
            <div className="col-sm-12 col-md-3">
              <InputSelect
                label="Herdar Preferências do Perfil"
                options={optionsSelect}
                placeholder="Selecione perfil pai.."
                value={formData.cod_controle_pai.value}
                iniInicializado={iniInicializado}
                isRequired={formData.cod_controle_pai.isRequired}
                setInvalid={formData.cod_controle_pai.isInvalid}
                onChange={(newValue: any, isInvalid = true) => {
                  handleChangePerfilPai(newValue, isInvalid);
                  handleSeacrhTelasPerfilPai(newValue.value);
                }}
              />
            </div>
            {flg_superadmin && (
              <div className="col-sm-12 col-md-3 mt-1">
                <ToggleDefault
                  labelText="Público?"
                  setChecked={publico}
                  onSwitch={() => {
                    setValue('flg_publico', !publico);
                    setPublico(!publico);
                  }}
                />
              </div>
            )}
            <div className="my-4">
              <TreeView
                className="col-sm-12 col-md-3"
                label="Acesso às telas:"
                arryModulosTelas={telas}
                invalid={invalidTreeView}
                onChange={(data: any) => {
                  handleChangeCheckedOptions(data);
                }}
              />
            </div>
          </div>
        </FormDefault>
      )}
    </Container>
  );
};

export default ControleDeAcesso;
