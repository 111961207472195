import styled from 'styled-components';
import { Modal } from 'react-bootstrap';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  .loja {
    .css-1a7rm5r-control {
      border-bottom: 2px solid #db1212;
    }
  }

  .invalidInput {
    border-bottom: 2px solid #db1212;
  }

  .disabledInput {
    opacity: 0.5;
    cursor: no-drop;
  }
`;
export const SeparatorVertical = styled.div`
  margin-top: 30%;
  width: 1px;
  height: 70%;
  background-color: #ccc;
`;

export const ErrorMessage = styled.p`
  font-size: 11px;
  font-weight: 500;
  color: #db1212;
  margin-top: 2px;
`;

export const BtnContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-top: 1.89rem;

  .btn-quitacao {
    background-color: #57069e;
    border-color: #29004c;
    width: 100px;
    &:focus {
      box-shadow: 0 0 0 0.25rem #c2a0f8;
    }
  }
`;

export const ReceiptModal = styled(Modal)`
  .modal-header {
    height: 100px;
    padding: 0px 80px;
    background: #fff;
    color: #58079e;
    border-bottom: transparent;

    .modal-title {
      margin-top: auto;
      width: 100%;
      /* background: red; */
      border-bottom: 1px solid #dee2e6;
      /* text-align: right; */
    }
  }
  .modal-body {
    margin-top: 20px;
    padding-left: 80px;
    padding-right: 80px;

    .modal-acoes {
      height: 70px;
      transition: 0.3s;
      cursor: pointer;

      & + .modal-acoes {
        border-top: 1px solid #ededed;
      }
      & + a {
        border-top: 1px solid #ededed !important;
      }
      h5 {
        font-size: 18px;
        color: #474747;
      }
      small {
        font-size: 13px;
      }
      svg {
        color: ${(props) => props.theme.colors.nav} !important;
      }
    }
    .executando {
      background: #ebebeb;
      transition: 0.3s;

      cursor: default;
      h5 {
        color: #a19d9d !important;
      }
      small {
        color: #a19d9d !important;
      }
      svg {
        color: #9d7dbd !important;
      }
      -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
      -khtml-user-select: none; /* Konqueror HTML */
      -moz-user-select: none; /* Old versions of Firefox */
      -ms-user-select: none; /* Internet Explorer/Edge */
      user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }
    .nav-tabs {
      border: none;
      .nav-item {
        display: none;
        visibility: hidden;
      }
    }
    .button-confirm {
      margin-top: 31px;
    }
  }
  .modal-footer {
    button {
      background: ${(props) => props.theme.colors.nav};
      border-color: ${(props) => props.theme.colors.nav};

      &:focus:not(.focus-visible) {
        outline: none !important;
        outline: 0px !important;
        -webkit-appearance: none;
        appearance: none;
        box-shadow: none !important;
      }
      &:focus-visible {
        outline: 2px solid ${(props) => props.theme.colors.nav} !important;
      }
    }
  }
`;
