import { yupResolver } from '@hookform/resolvers/yup';
import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useState,
} from 'react';
import { getSchema } from './validations';
import {
  Control,
  FieldValues,
  FormState,
  useForm,
  UseFormClearErrors,
  UseFormGetValues,
  UseFormHandleSubmit,
  UseFormRegister,
  UseFormReset,
  UseFormSetError,
  UseFormSetFocus,
  UseFormSetValue,
  UseFormTrigger,
  UseFormWatch,
} from 'react-hook-form';
import { toast } from 'react-toastify';
import { jsPDF } from 'jspdf';

type FormType = {
  register: UseFormRegister<FieldValues>;
  handleSubmit: UseFormHandleSubmit<FieldValues>;
  control: Control<FieldValues, any>;
  reset: UseFormReset<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
  getValues: UseFormGetValues<FieldValues>;
  formState: FormState<FieldValues>;
  watch: UseFormWatch<FieldValues>;
  setError: UseFormSetError<FieldValues>;
  clearErrors: UseFormClearErrors<FieldValues>;
  setFocus: UseFormSetFocus<FieldValues>;
  trigger: UseFormTrigger<FieldValues>;
};

type ContasAReceberContextData = {
  formContasAReceber: FormType;
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  gerarEbaixarPDF: (receipt?: string, imprimir?: boolean) => void;

  recibo: string;
  setRecibo: React.Dispatch<React.SetStateAction<string>>;
  isUpdate: boolean;
  setUpdate: (update: boolean) => void;
};

export const ContasAReceberContext = createContext(
  {} as ContasAReceberContextData,
);

interface ContasAReceberContextProviderProps {
  children: ReactNode;
}

export function ContasAReceberContextProvider({
  children,
}: ContasAReceberContextProviderProps): JSX.Element {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [recibo, setRecibo] = useState<string>('');
  const [isUpdate, setIsUpdate] = useState(false);

  /**
   * Form ContasAReceber
   */
  const {
    register,
    handleSubmit,
    control,
    setValue,
    getValues,
    setError,
    setFocus,
    clearErrors,
    formState,
    watch,
    reset,
    trigger,
  } = useForm({
    resolver: yupResolver(getSchema(isUpdate)),
    reValidateMode: 'onBlur',
  });

  const setUpdate = useCallback((update: boolean) => {
    setIsUpdate(update);
  }, []);

  const gerarEbaixarPDF = (receipt?: string, imprimir = false) => {
    const receiptInText = receipt || recibo;
    const linhas = receiptInText.split('\n');
    const numeroDeLinhas = linhas.length;

    const espacamentoEntreLinhas = 5; // Ajuste este valor conforme necessário
    const alturaPagina = numeroDeLinhas * espacamentoEntreLinhas + 20; // + 20mm de margem
    const larguraPagina = 80;

    // eslint-disable-next-line new-cap
    const doc = new jsPDF({
      orientation: 'p',
      unit: 'mm',
      // format: [4 * 25.4, 6 * 25.4], // Aproximadamente 4x6 polegadas
      format: [larguraPagina, alturaPagina],
    });

    doc.setFont('Courier'); // Define a fonte para Courier
    doc.setFontSize(8); // Define o tamanho da fonte
    doc.setTextColor(0, 0, 0); // Define a cor do texto para preto

    linhas.forEach((linha, index) => {
      doc.text(linha, 0, 10 + index * espacamentoEntreLinhas);
    });

    if (imprimir) {
      // Obtém o conteúdo do PDF como uma string
      const pdfContent = doc.output();

      // Abre o conteúdo do PDF em uma nova janela ou guia
      const newWindow = window.open('', '_blank');
      if (newWindow) {
        newWindow.document.write(
          '<html><head><title>Recibo</title></head><body style="margin: 0; padding: 0;">',
        );
        newWindow.document.write(
          `<embed width="100%" height="100%" name="plugin" src="data:application/pdf;base64,${btoa(
            pdfContent,
          )}" type="application/pdf">`,
        );
        newWindow.document.write('</body></html>');
      } else {
        toast.error('Não foi possível abrir o recibo para impressão.');
      }
      return;
    }
    doc.save('recibo.pdf');
  };

  return (
    <ContasAReceberContext.Provider
      value={{
        formContasAReceber: {
          watch,
          reset,
          control,
          register,
          setValue,
          setError,
          getValues,
          setFocus,
          formState,
          clearErrors,
          handleSubmit,
          trigger,
        },
        showModal,
        setShowModal,
        gerarEbaixarPDF,
        recibo,
        setRecibo,
        isUpdate,
        setUpdate,
      }}
    >
      {children}
    </ContasAReceberContext.Provider>
  );
}

export const useContasAReceber = (): ContasAReceberContextData => {
  return useContext(ContasAReceberContext);
};
